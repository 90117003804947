
.header {

  h1 {
    margin: 0;
    position: relative;
    top: 14px;
  }

  .header-container {
    width: 1140px;
    position: relative;
    margin: auto;
    height: 110px;

    @include ifmedia(md){
      width: 960px;
    }

    .burger {
      display: none;
      position: relative;
    }

    &::after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: -1;
      opacity: .4;
      background-image: url("/public/img/footer.svg");
      background-repeat: no-repeat;
      background-size: 35%;
      background-position: 80% bottom;
    }

    .logout {
      display: block;
    }



    .fast-link {
      position: absolute;
      transform: scale(.9);

      &.fast-link-logged {
        a {
          width: 83px;
        }

        .fast-link-logout {
          display: none;
        }
      }

      a {
        top: -10px;
        transition: all .2s ease-in-out;

        &.active {
          top: 10px;
        }
      }

      &:hover a {
        top: -10px;

        &:hover {
          top: 10px;
        }
      }
    }
  }
}
