.page {
  .cards {
    .card a {
      height: 195px;
      .more {
        display: none;
      }

      p, h3 {
        color: darken(white, 10%);
      }

      &:hover {
        text-decoration: none;
        p, h3 {
          color: white;
        }
        .more {
          display: block;
        }
      }
    }
  }
}
