@import '../../sprites';

.anav {
  width: 100%;
  display: block;
  position: fixed;
  top: 110px;
  height: auto;
  z-index: 600;
  bottom: auto;
  width: 100%;
  background-color: $blue-dark;
  border-top: 2px solid $green;
  overflow: visible;

  .navhead {
    display: none;
  }

  & > ul {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin: auto;
    background: none;

    @include ifmedia(lg) {
      width: 1140px;
    }

    @include ifmedia(md) {
      width: 940px;
    }

    & > li:last-child ul {
      display: none !important;
    }

    & > li {
      background: none;
      border-bottom: 0;
      overflow: visible;
      position: relative;

      &.home a {
        text-indent: -9000px;
        @include sprite($home) position: relative;
        top: 5px;
      }

      &.home:hover {
        background: none;
      }

      .icon {
        display: none;
      }

      &.navhead {
        display: none !important;
      }

      & > a {
        color: white;
        text-transform: uppercase;
        padding: 10px;
        line-height: 20px;
      }

      &.mleft > ul {
        left: auto;
        right: 0;
      }

      &.mright > ul {
        left: 0;
        right: auto;
      }

      & > ul {
        display: none;
        background-color: $green;
        width: 300px;
        top: 40px;
        position: absolute;
        height: auto;

        li {
          &:first-child {
            padding-top: 8px;
          }

          width: auto;
          background: none;

          a {
            color: white;
            line-height: 1.6rem;
            position: relative;
          }

          .icon-menu-left {
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%) rotate(180deg);
          }

          &.navhead {
            display: none !important;
          }
        }
      }

      &:hover {
        background-color: $green;

        & > a {
          color: $blue-dark;
        }

        & > ul {
          display: block;

          & > li {
            display: block;
            position: relative;
            margin: 0;
            border: 0;
            border-bottom: 1px solid darken($green, 4%);

            ul {
              position: absolute;
              width: 300px;
              left: -300px;
              top: 0;
              background-color: $green;
              height: auto;
              display: none;
            }

            &.active a {
              color: $blue-dark;
            }

            &:hover {
              background-color: darken($green, 4%);

              & > a {
                color: $blue-dark;
              }

              ul {
                display: block;
                background-color: darken($green, 4%);

                li {
                  margin: 0;
                  border: 0;
                  border-bottom: 1px solid darken($green, 8%);
                }

                li:hover {
                  background-color: darken($green, 8%);

                  & > a {
                    color: darken($blue-dark, 8%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.anav > ul > li:hover > ul > li ul {
  left: 300px;
}
