.articles-list {
  .article {
    padding: 0 15px;
    margin-bottom: 15px;

    &:nth-child(4n + 1),
    &:nth-child(4n + 4) {
      h3 {
        background-color: $blue-light;
      }
    }

    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      h3 {
        background-color: $green;
      }
    }

    @media (max-width: 1200px) {
      &:nth-child(odd) h3 {
        background-color: $blue-light;
      }

      &:nth-child(even) h3 {
        background-color: $green;
      }
    }
  }
}
