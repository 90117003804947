.page {
  .page-header {
    max-height: 300px;
    overflow: hidden;
    h1 {
      padding: 15px 20px;
      font-size: 2.5rem;
    }
  }

  .page-footer p {
    font-size: 1.3rem;
  }
}

.article {
  padding-left: 0;
  margin-left: -15px;
}

.sub-menu {
  & > ul {
    font-family: $montserrat;

    li.active {
      background-color: $grey-light;
      a {
        color: $blue;

        &:hover {
          text-decoration: none;
        }
      }
    }

    li {
      a:hover {
        color: $blue;

        &:hover {
          text-decoration: none;
        }
      }
    }

    & > li {
      position: relative;
      border-bottom: 1px solid $green;

      a {
        display: block;
        padding: 8px 5px;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $grey4;
        position: relative;
      }

      &.hasChild > a {
        padding-right: 30px;
        .icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
      }

      &:last-child {
        border-bottom: 0px;
        & > a::after {
          display: none;
        }
      }

      & > a::after {
        position: absolute;
        content: '';
        height: 1px;
        background-color: $green;
        bottom: 1px;
        left: 0;
        right: 0;
      }
      & > ul {
        & > li {
          border-top: 1px solid $green;
          a {
            padding-left: 30px;
          }
        }
      }
    }
  }
}

@import 'card';
@import 'contact';
@import 'project';
@import 'trombi-testimonial';
@import 'documentation';
