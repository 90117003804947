
h2.sep {
  font-size: 2.5rem;
}

@media (min-width: 1400px)  {
  .search-btn {
    position: fixed;
    left: 50%;
    margin-left: 600px;
    right: auto;
    top: 300px;


    &-tablette {
      display: none;
    }

    &-mobile {
      display: block;
    }
  }
}
