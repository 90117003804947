

.footer {
  padding-top: 0;

  .footer-top {

    ul {
      a:hover {
        color: $blue-dark;
        text-decoration: none;
      }
    }

    .container {
      background-size: 50%;
    }

    .contact {
      padding-top: 20px;
      margin: 0;
      margin-left: 10px;
      width: 280px;
      background-color: $green-light;
      text-align: center;

      h2 {
        color: $blue-dark;
        font-size: 1.8rem;
      }

      p {
        margin-top: 30px;
        color: $blue-dark;
        line-height: 2.8rem;
        a {
          color: $blue-dark;
        }

        &.social-text {
          color: white;
          line-height: 1.2;
        }
      }

      .social-grid {
        display: flex;
        justify-content: space-around;
        margin: 15px;

        a .icon {
          text-indent: -3000px;
        }
      }

      .btn {
        display: block;
        margin-top: 30px;
        font-size: 1.8rem;
        font-weight: 700;

        .icon {
          margin-left: 10px;
          position: relative;
          top: 3px;
          transition: transform .3s ease-in-out;
        }

        &:hover {
          text-decoration: none;
          .icon {
            transform: translateX(10px);
          }
        }
      }

      .contact-bottom {
        display: block;
        margin-top: 30px;
        position: relative;
        padding-left: 65px;
        padding-top: 5px;
        color: white;
        text-align: left;
        line-height: 2rem;
        .icon {
          position: absolute;
          top: 0;
          left: 10px;
        }

        & + .contact-bottom {
          margin-top: 20px;
        }
      }
    }
  }

  .footer-bottom {
    width : 100%;
    height: auto;
    text-align: left;

    ul {
      li {
        display: inline-block;
        padding: 0 10px 0 0;
        line-height: 2.3rem;
      }
    }
  }

}
