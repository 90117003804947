
.page {
  .projects {

    #map {
      height: 300px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      li {
        width: 20%;

        @include ifmedia(md){
          width: 30%;
        }
        margin-bottom: 15px;



        &:hover {
          background-color: rgba($green, .6);

          a {
            text-decoration: none;
          }

          a h3, a p {
            color: white;
          }
        }

        a {
          display: block;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: relative;
          overflow: hidden;

          img {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
