
.student.choice {
  .actions {
    a {
      min-height: 120px;
      padding-left: 140px;

      .label {
          font-size: 1.8rem;
      }

      & > .icon {
        left: 18px;
        transform: translateY(-50%)  scale(1);
      }
    }
  }
}


.album .page  article ul li {
  width: 33%;

  a {
    .album-foot {
      .show {
        visibility: hidden;
      }
    }

    &:hover .album-foot .show {
      visibility: visible;
    }
  }
}

.album .page.album-show article ul li {
  width: 25%;
  a {
    .album-foot {
      display: none;
    }

    &:hover .album-foot {
      display: block;
    }

  }
}


#top-vue {
  top: -160px;
}
