


.col-carousel {
  // margin-top: 77px;
  padding-left: 15px;
  padding-right: 15px;

  .bxslider-container {
    position: relative;

    .bxslider-nav #bxslider-next {
      right: 380px;
      z-index: 10;
    }

    .bxslider-pager {
      width: 320px;
      text-align: center;
    }

    .bxslider-content {
      width: 400px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url(/public/img/carousel.png);


      li {
        position: absolute;
        top: 20px;
        left: 85px;
        right: 10px;
        min-height: 400px;

        h2 {
          margin-bottom: 20px;
        }

        p {
          padding-bottom: 15px;
          &.bxslider-link {
            position: static;
            bottom: auto;
            left: auto;
            padding: 0;

            .icon {
              position: relative;
              left: 0;
            }

            &:hover .icon {
              left: 10px;
              transition: left .2s ease-in-out;
            }
          }
        }
      }
    }
  }
}

.homelink {
  overflow: hidden;
  position: relative;

  .homelink-content {
    height: auto;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .valign {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: none;
      top: 0;
      margin: 0;
      height: 80px;
      z-index: 20;

      h3 {
        top: 0;
        left: 0;
        right: 0;
        font-size: 2.4rem;
        line-height: 3rem;
        font-weight: normal;
      }
    }

    $effect: cubic-bezier(0.785, 0.135, 0.15, 0.86);

    .description {
      transition: transform .8s $effect;
      position: absolute;
      top: 80px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(black, .5);
      padding: 30px;
      padding-top: 60px;
      z-index: 10;
      display: block;

      p {
        color: white;
        font-size: 1.5rem;
      }

      .icon {
        display: block;
        margin: auto;
        margin-top: 20px;
      }
    }
  }

  &.homelink-blue, &.homelink-green {
    img {
      transform: none;
    }
  }

  &.homelink-blue .homelink-content .valign {
    background-color: $blue-light;

  }

  &.homelink-green .homelink-content .valign {
    background-color: $green;
  }

  &.homelink-green {
    .valign {
      top: auto;
      bottom: 0;
    }
    .description {
      top: 0;
      bottom: 80px;
      transform: translateY(360px);
    }

    &:hover .description {
      transform: translateY(0px);
    }
  }

  &.homelink-blue {
    .valign {
      top: 0;
      bottom: auto;
    }
    .description {
      bottom: 0;
      top: 80px;
      transform: translateY(-360px);
    }

    &:hover .description {
      transform: translateY(0px);
    }

  }
}


.testimonials  {
  .testimonial {
    margin-top: 20px;
    margin-bottom: 50px;
    height: 180px;

    &:hover {

      .description {
        display: block;
      }

      .img.bg {
        opacity: .3;
      }
      .img.user {
        background-color: #c3e9eb;

        img {
          visibility: hidden;
        }
      }

      .description {
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        padding: 30px 15px 30px;
        text-align: left;
        background-color: rgba(#d9ecf3, .54);

        .icon-quotes {
          margin: 5px 10px 5px 0;
        }

        .icon-more {
          display: block;
          position: absolute;
          bottom: 10px;
          margin: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .work {
        display: none;
      }

      h3 {
        position: absolute;
        top: -15px;
        right: 0;
        z-index: 20;
      }
    }
  }

  .btn {
    font-size: 1.5rem;
    border: 3px solid $green;
    font-family: $montserrat;
    &:hover {
      text-decoration: none;
      background: none;
      color: $green;
      .icon-breadcrumb {
        display: block;
      }
      .icon-arrow-white-menu{
        display: none;
      }
    }
  }
}


.logo-quality {
  flex-wrap: nowrap;
  div {
    padding: 10px;
    img {
      width: 90%;
    }
  }
}
