
.trombi {

  .fake-img {
    width: 75px;
    height: 75px;
    display: block;
    background-color: white;
    float: left;
    margin: 0 10px 5px 0;
  }

  .more {
    display: none;
  }

  &:hover {
    text-decoration: none;

    .more {
      display: block;
    }
  }
}
